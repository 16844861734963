<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="'编辑'" width="500px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px">
      <el-form-item label="站点名称">
        <el-input v-model="form.siteName" :disabled="true" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="栏目名称">
        <el-input v-model="form.columnName" :disabled="true" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="组件名称">
        <el-input v-model="form.moduleName" :disabled="true" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="是否显示" prop="isShow">
        <el-radio v-model="form.isShow" label=1>是</el-radio>
        <el-radio v-model="form.isShow" label=0>否</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/cms/srCmsRelation'
export default {
  props: {
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false, dialog: false,
      form: {
        id: '',
        siteId: '',
        siteName: '',
        columnId: '',
        columnName: '',
        moduleId: '',
        moduleName: '',
        isShow: ''
      },
      rules:{

      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        siteId: '',
        siteName: '',
        columnId: '',
        columnName: '',
        moduleId: '',
        moduleName: '',
        isShow: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
