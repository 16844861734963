import request from '@/utils/request'

export function del(id) {
  return request({
    url: 'cms/crm/srCmsRelation/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'cms/crm/srCmsRelation',
    method: 'put',
    data
  })
}
